<!-- TODO: Update logic for this -->
<template>
  <div :class="containerClass">
    <s-dropdown
      v-model="value"
      v-bind="dropdownProps"
      :closeOnClick="closeOnClick"
      class="st-dropdown relative"
      :isDisabled="disabled"
      :class="searchable ? 'searchable' : ''"
      @update:modelValue="handleUpdateChange"
    >
      <s-dropdown-button :class="dropdownBtnClassRender">
        <span :class="{ 'text-placeholder': placeholder && selectedOption.label === '' }">
          <slot
            v-if="hasSlot(`dropdown-button`)"
            :name="'dropdown-button'"
            :selectedOption="selectedOption"
          ></slot>
          <template v-else>
            {{ selectedOption.label === '' ? $t(placeholder) : $t(selectedOption.label) }}
          </template>
        </span>
      </s-dropdown-button>

      <template #dropdownItems>
        <li
          v-if="searchable && options.length > 0"
          class="px-12 sticky bg-[#fff] top-0 z-[51] py-8 w-full"
        >
          <input-text
            v-model="searchValue"
            searchable
            size="sm"
            variant="outline"
            :placeholder="dropdownPlaceholder ?? $t('studio.common.search_place_holder')"
          />
        </li>
        <template v-if="optionRenderer.length > 0">
          <template v-for="(opt, index) in optionRenderer">
            <slot v-if="hasSlot(`option-${index}`)" :name="`option-${index}`" :option="opt"></slot>
            <slot v-else-if="hasSlot(`default`)" :name="'default'" :option="opt"></slot>

            <s-dropdown-item
              v-else
              :key="`${opt.value}-${index}`"
              :value="opt.value"
              :class="dropdownProps.itemDropdownClass"
              class="max-w-full"
            >
              <st-tooltip-text-over
                v-if="useTooltip"
                position="bottom-start"
                hasArrow
                :content="$t(opt.label)"
              >
                <span class="line-clamp-1 break-all text-md font-bold truncate block">{{
                  $t(opt.label)
                }}</span>
              </st-tooltip-text-over>

              <span v-else class="truncate tracking-[0] block">{{ $t(opt.label) }}</span>
            </s-dropdown-item>
          </template>
        </template>
        <template v-else>
          <slot v-if="hasSlot(`custom-empty`)" name="custom-empty"></slot>
          <li v-else class="pointer-events-none pt-8" aria-disabled="true">
            <button class="stds-dropdown-list-item relative">
              {{
                options.length > 0
                  ? $t(`${emptySearchText}`)
                  : $t(noDataText ? noDataText : emptySearchText)
              }}
            </button>
          </li>
        </template>
      </template>
    </s-dropdown>
  </div>
  <st-error-message :name="fieldName" :showError="showFieldError" />
</template>

<script setup lang="ts">
import { computed, ref, toRefs, useSlots, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import InputText from '@/components/validation/input-text.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { DropdownProps, FormOption, FormOptionGroup } from '@/types/common/form.type';
import type { ValidationRule } from '@/types/validation.type';

const slots = useSlots();

const { t } = useI18n();

const props = withDefaults(defineProps<DropdownProps>(), {
  placeholder: '',
  label: '',
  rules: undefined,
  name: undefined,
  modelValue: '',
  description: undefined,
  closeOnClick: true,
  searchable: false,
  useTooltip: false,
  emptySearchText: 'studio.group.collection.register_product_config_place_holder_msg1',
  dropdownProps: () => ({
    distance: 4,
    placement: 'bottom-start',
    size: 'sm',
    offset: [0, 0],
    variant: 'line'
  }),
  useTranslate: false
});

const emit = defineEmits<{
  'update:modelValue': [v: string | number]; // For v-model usage
}>();

const hasSlot = (name: string) => !!slots[name];

const { options } = toRefs(props);

const value = ref(props.modelValue);
const searchValue = ref('');
const showFieldError = ref(false);
const setFieldValue = ref<(value: string | number) => void>();

const fieldName = computed<string>(() => props.name ?? '');

const optionRenderer = computed(() => {
  return options.value.filter((option: FormOption<string | number>) => {
    if (props.useTranslate) {
      return (
        t(option.label).toLowerCase().includes(searchValue.value.toLowerCase()) || !props.searchable
      );
    }
    return (
      option.label.toLowerCase().includes(searchValue.value.toLowerCase()) || !props.searchable
    );
  });
});

const dropdownBtnClassRender = computed(() => ({
  [`${props.dropdownBtnClass}`]: !!props.dropdownBtnClass,
  'border-2 border-error': showFieldError.value
}));

const selectedOption = computed((): FormOptionGroup<string | number> => {
  return (
    options.value.find((option: FormOption<string | number>) => option.value === value.value) || {
      value: '',
      label: ''
    }
  );
});

const handleUpdateChange = (e: string | number) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }
  emit('update:modelValue', e);
};

if (fieldName.value) {
  const rules = computed<ValidationRule | undefined>(() => props.rules);
  const { setValue, showError } = useValidation<string | number>({
    fieldName: fieldName.value,
    rules,
    value
  });

  setFieldValue.value = setValue;
  showFieldError.value = showError.value;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

watch(
  () => props.modelValue,
  (v: string | number) => {
    value.value = v;
  }
);
</script>
<style lang="scss">
.st-dropdown.searchable + div {
  .stds-dropdown-list {
    @apply relative;
    @apply pt-0;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .stds-dropdown-list::-webkit-scrollbar {
    @apply hidden;
  }
}
</style>
